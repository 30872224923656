import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";

let ph_loaded = false;

// load sentry immediately without waiting for full document ready
// if credentials available (stag/prod)
if (window.sentryDSN) {
  Sentry.init({
    dsn: window.sentryDSN,
    release: window.sentryRelease,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Can't find variable: DragEvent", // posthog - iOS/recorder.js
    ],
    blacklistUrls: ["https://preview.tab.travel/"],
    environment: window.sentryEnvironment,
  });
}

// load posthog immediately without waiting for full document ready
if (!window.DEBUG && window.POSTHOG_API_KEY) {
  posthog.init(window.POSTHOG_API_KEY, {
    api_host: "https://app.posthog.com",
    loaded: () => (ph_loaded = true),
  });
} else {
  ph_loaded = true;
}

function whenReady(f) {
  // wait on posthog to be init'd
  // otherwise it can fail
  if (ph_loaded) {
    try {
      f();
    } catch (e) {
      // don't let analytics scripts kill the page
      Sentry.captureException(e);
    }
  } else {
    setTimeout(() => whenReady(f), 500);
  }
}

const analytics_blank = {
  identify: (userID) => null,
  setMetadata: (properties) => null,
  capture: (event, properties) => null,
  capture_pageview: (url) => null,
};

const analytics_posthog = {
  identify: (userID) => whenReady(() => posthog.identify(userID)),
  setMetadata: (properties) => whenReady(() => posthog.people.set(properties)),
  capture: (event, properties) => whenReady(() => posthog.capture(event, properties)),
  capture_pageview: (url) => whenReady(() => posthog.capture_pageview(url)),
};

const analytics_console = {
  identify: (userID) => whenReady(() => console.log(`identify(${userID})`)),
  setMetadata: (properties) => whenReady(() => console.log(`setMetadata(${properties})`)),
  capture: (event, properties) =>
    whenReady(() => console.log(`capture(${event}, ${properties})`)),
  capture_pageview: (url) => whenReady(() => console.log(`capture_pageview(${url})`)),
};

export const analytics = window.DEBUG ? analytics_console : analytics_posthog;
